import {
  DesignTypeDrawing,
  ErpSystem,
  LoopTypeService,
  MaterialConstituent,
  NettingTypeService,
  PackagingProduct,
  RopeTypeService,
} from 'models';
import { AnalysisVariantLoad } from './AnalysisVariant';
import { Connection, Coupling } from './Connections';
import { DimensionClassType } from './NetDimension';
import { options, QueryOption, range, readOnly } from './restutil';
import { RopeDirection } from './RopeDirection';
import { Impregnated, ImpregnatedChoice } from './Impregnated';
import { Expose } from 'class-transformer';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Models {
  export enum AuditLogAction {
    Create,
    Update,
    Delete,
    CustomAction,
  }

  export enum MeasurementPlacements {
    JumpNet,
    Waterline,
    MiddleSide,
    Bottom,
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Address {
    public static ApiUrl = 'api/addresses';

    Address1: string;
    @range(null, 100)
    Address2: string;
    @range(null, 50)
    Zip: string;
    @range(null, 50)
    City: string;
    @range(null, 50)
    Country: string;
    CustomerId: number;
    Customer: Customer;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Analysis {
    public static ApiUrl = 'api/analyses';

    Comment: string;
    @range(null, 100)
    ReportReference: string;
    ArchiveInformation: string;
    @range(null, 1000)
    SigningComment?: string;
    SignedDate?: Date;
    @readOnly()
    Locked: boolean;
    @range(null, 450)
    SignedByUserId?: string;
    @readOnly()
    SignedByUserName?: string;
    CoSignedDate?: Date;
    @range(null, 450)
    CoSignedByUserId?: string;
    @readOnly()
    CoSignedByUserName?: string;
    CustomerId: number;
    Customer: Customer;
    DrawingFileId?: number;
    DrawingFile: StorageFile;
    AnalysisDesignId?: number;
    AnalysisDesign: AnalysisDesign;
    Variants: any[];
    Ropes: any[];
    Nettings: any[];
    Loops: any[];
    FileAttachments: any[];
    Id: number;
    NetStandardId: number;
    NetStandard?: NetStandard;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class AnalysisDeadFishSystem {
    public static ApiUrl = 'api/analysisdeadfishsystems';

    WeightAirDeadfishSinker?: number;
    WeightAirDeadfishSinkerToleranceDown?: number;
    WeightAirDeadfishSinkerToleranceUp?: number;
    WeightSeaDeadfishSinker?: number;
    WeightSeaDeadfishSinkerToleranceDown?: number;
    WeightSeaDeadfishSinkerToleranceUp?: number;
    LiftupDeadfish?: boolean;
    LiftupDeadFishPocket?: number;
    LiftupDeadFishPocketToleranceDown?: number;
    LiftupDeadFishPocketToleranceUp?: number;
    LiftupDeadFishPocketDepth?: number;
    LiftupDeadFishPocketDepthToleranceDown?: number;
    LiftupDeadFishPocketDepthToleranceUp?: number;
    LiftupDeadFishReinforcementCenter?: number;
    LiftupDeadFishReinforcementCenterToleranceDown?: number;
    LiftupDeadFishReinforcementCenterToleranceUp?: number;
    LiftupDeadFishReinforcementCenterSolidity?: number;
    LiftupDeadFishReinforcementCenterSolidityToleranceDown?: number;
    LiftupDeadFishReinforcementCenterSolidityToleranceUp?: number;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class AnalysisDesign {
    public static ApiUrl = 'api/analysisdesigns';

    FloaterNetConnections?: number;
    FloaterCircumference?: number;
    // Circumference
    CircumferenceProduction?: number;
    CircumferenceProductionToleranceUp?: number;
    CircumferenceProductionToleranceDown?: number;

    CircumferenceBottomRopeProduction?: number;
    CircumferenceBottomRopeProductionToleranceUp?: number;
    CircumferenceBottomRopeProductionToleranceDown?: number;

    // Bottom
    DepthBottom?: number;
    DepthBottomToleranceUp?: number;
    DepthBottomToleranceDown?: number;
    // Bottom center
    DepthBottomCenter?: number;
    DepthBottomCenterToleranceUp?: number;
    DepthBottomCenterToleranceDown?: number;
    // Outer bottom
    DepthOuterBottom?: number;
    DepthOuterBottomToleranceUp?: number;
    DepthOuterBottomToleranceDown?: number;
    // Extra bottom
    DepthBottomCenterExtraBottom?: number;
    DepthBottomCenterExtraBottomToleranceUp?: number;
    DepthBottomCenterExtraBottomToleranceDown?: number;
    // SDR
    StandardDimensionRatio?: number;

    // Combinet specifics
    IsCombiNet: boolean;
    DepthTopRopeSmolt?: number;
    DepthTopRopeSmoltToleranceUp?: number;
    DepthTopRopeSmoltToleranceDown?: number;
    DepthWaterlineRopeSmolt?: number;
    DepthWaterlineRopeSmoltToleranceUp?: number;
    DepthWaterlineRopeSmoltToleranceDown?: number;

    @readOnly()
    TotalDepth?: number;
    NumberOfSides?: number;
    NumberOfSideRopes?: number;
    NumberOfLiftingRopes?: number;
    NumberOfCrossRopes?: number;
    NumberOfCrossRopesSpagetti?: number;
    NetShapeId?: number;
    NetShape: NetShape;
    DesignTypeId?: number;
    DesignType: DesignType;
    FloaterTypeId?: number;
    FloaterType: FloaterType;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class AnalysisLoop {
    public static ApiUrl = 'api/analysisloops';

    MinBreakingStrength?: number;
    MinBreakingStrengthToleranceDown?: number;
    MinBreakingStrengthToleranceUp?: number;
    VariantMaxMinBreakingStrength?: number;
    AnalysisId: number;
    Analysis: Analysis;
    LoopTypeId?: number;
    LoopType: LoopType;
    LoopPositionVerticalId?: number;
    LoopPositionVertical: LoopPositionVertical;
    LoopPositionHorizontalId?: number;
    LoopPositionHorizontal: LoopPositionHorizontal;

    CouplingId?: number;
    Coupling?: Coupling;

    IsAttachmentPointCage: boolean;
    IsAttachmentPointPlumb: boolean;

    IsAttachmentPointTopLoop: boolean;
    IsAttachmentPointMageband: boolean;

    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class AnalysisNetting {
    public static ApiUrl = 'api/analysisnettings';

    MinBreakingStrength?: number;
    MinBreakingStrengthToleranceDown?: number;
    MinBreakingStrengthToleranceUp?: number;
    ActualBreakingStrength?: number;
    Solidity?: number;
    SolidityToleranceDown?: number;
    SolidityToleranceUp?: number;
    VariantMaxMinBreakingStrength?: number;
    NetThreadDiameter?: number;
    AnalysisId: number;
    Analysis: Analysis;
    PlacementId?: number;
    Placement: Placement;
    MaterialId?: number;
    Material: Material;
    MeshSizeId?: number;
    MeshSize: MeshSize;
    ThreadTypeId?: number;
    ThreadType: ThreadType;
    NetMaskTypeId?: number;
    NetMaskType: NetMaskType;
    NettingTypeId?: number;
    NettingType: NettingType;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class AnalysisRingSinker {
    public static ApiUrl = 'api/analysisringsinkers';

    RingSinkerFloaterConnectionNumber?: number;

    BottomRingDirectlyOnNet?: boolean;

    WeightAir?: number;
    WeightAirToleranceDown?: number;
    WeightAirToleranceUp?: number;

    TubeDiameter?: number;
    TubeDiameterToleranceDown?: number;
    TubeDiameterToleranceUp?: number;

    BottomRingCircumference?: number;
    BottomRingCircumferenceToleranceDown?: number;
    BottomRingCircumferenceToleranceUp?: number;

    SDR?: number;
    SDRToleranceDown?: number;
    SDRToleranceUp?: number;

    CalculatedNegativeBuoyancy?: number;
    CalculatedNegativeBuoyancyToleranceDown?: number;
    CalculatedNegativeBuoyancyToleranceUp?: number;

    CenterWeight?: number;
    CenterWeightToleranceDown?: number;
    CenterWeightToleranceUp?: number;

    SingleSinker?: number;
    SingleSinkerToleranceDown?: number;
    SingleSinkerToleranceUp?: number;

    SingleSinkerOther?: number;
    SingleSinkerOtherToleranceDown?: number;
    SingleSinkerOtherToleranceUp?: number;

    // Utgått
    WeightSea?: number;
    WeightSeaToleranceDown?: number;
    WeightSeaToleranceUp?: number;

    TubeWallThickness?: number;
    TubeWallThicknessToleranceDown?: number;
    TubeWallThicknessToleranceUp?: number;

    RingSinkerNetConnectionNumber?: number;

    MinBreakingStrengthChainFloaterRingSinker?: number;
    MinBreakingStrengthChainFloaterRingSinkerToleranceDown?: number;
    MinBreakingStrengthChainFloaterRingSinkerToleranceUp?: number;

    RingSinkerNetConnectionTypeId?: number;
    RingSinkerNetConnectionType: RingSinkerNetConnectionType;

    RingSinkerFloaterConnectionTypeId?: number;
    RingSinkerFloaterConnectionType: RingSinkerFloaterConnectionType;

    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class AnalysisRope {
    public static ApiUrl = 'api/analysisropes';

    MinBreakingStrength?: number;
    MinBreakingStrengthToleranceDown?: number;
    MinBreakingStrengthToleranceUp?: number;
    Depth?: number;
    Elong?: number;
    ElongToleranceDown?: number;
    ElongToleranceUp?: number;
    VariantMaxMinBreakingStrength?: number;
    AnalysisId: number;
    Analysis: Analysis;
    RopePlacementId?: number;
    RopePlacement: RopePlacement;
    MaterialId?: number;
    Material: Material;
    RopeDimensionId?: number;
    RopeDimension: RopeDimension;
    RopeTypeId?: number;
    RopeType: RopeType;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class AnalysisSingleSinker {
    public static ApiUrl = 'api/analysissinglesinkers';

    WeightAir?: number;
    WeightAirToleranceDown?: number;
    WeightAirToleranceUp?: number;

    NetConnectionGlideloddWeightAir?: number;
    NetConnectionGlideloddWeightAirToleranceDown?: number;
    NetConnectionGlideloddWeightAirToleranceUp?: number;

    SumSinkersOnRopeWeightAir?: number;
    SumSinkersOnRopeWeightAirToleranceDown?: number;
    SumSinkersOnRopeWeightAirToleranceUp?: number;

    NetCenterSinkerWeightAir?: number;
    NetCenterSinkerWeightAirToleranceDown?: number;
    NetCenterSinkerWeightAirToleranceUp?: number;

    // UTGÅTT
    NumberSingelSinker?: number;

    SinkerConnectedWithSeparateRopesAndGlidelodd?: boolean;

    WeightSea?: number;
    WeightSeaToleranceDown?: number;
    WeightSeaToleranceUp?: number;

    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class AnalysisSpagetthiSinker {
    public static ApiUrl = 'api/analysisspagetthisinkers';

    NetUsedInOuterBottom?: boolean;

    WeightAir?: number;
    WeightAirToleranceDown?: number;
    WeightAirToleranceUp?: number;

    WeightDeadFishSystem?: number;
    WeightDeadFishSystemToleranceDown?: number;
    WeightDeadFishSystemToleranceUp?: number;

    SolidityBegrodd?: number;
    SolidityBegroddToleranceDown?: number;
    SolidityBegroddToleranceUp?: number;

    // UTGÅTT
    WeightSea?: number;
    WeightSeaToleranceDown?: number;
    WeightSeaToleranceUp?: number;

    Depth?: number;

    Umbilical?: boolean;

    NumberSpagettiRope?: number;

    HasNettingOnOuterBottom?: boolean;

    MaterialId?: number;
    Material: Material;

    NettingMinBreakingStrength?: number;
    NettingMinBreakingStrengthToleranceDown?: number;
    NettingMinBreakingStrengthToleranceUp?: number;

    Solidity?: number;
    SolidityToleranceDown?: number;
    SolidityToleranceUp?: number;

    RopeMinBreakingStrength?: number;
    RopeMinBreakingStrengthToleranceDown?: number;
    RopeMinBreakingStrengthToleranceUp?: number;

    RopeDimensionId?: number;
    RopeDimension: RopeDimension;

    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class AnalysisVariant {
    public static ApiUrl = 'api/analysisvariants';

    Comment: string;
    EnvironmentHs50Y?: number;
    EnvironmentVs50Y5m?: number;
    EnvironmentTp50Y?: number;
    EnviromentVs50Y15m?: number;

    ArchiveInformation: string;
    AnalyzicCombinationCompSignDate?: Date;

    SignedDate?: Date;

    @readOnly()
    Locked: boolean;

    @range(null, 1000)
    SigningComment: string;

    MaximumLoadFastningPointDescendingSystem?: number;
    MaximumLoadFasteningPointCage?: number;

    TotalSafetyFactorRopesALS?: number;
    TotalSafetyFactorRopesULS?: number;

    TotalSafetyFactorNettingsALS?: number;
    TotalSafetyFactorNettingsULS?: number;

    TotalSafetyFactorLoopsALS?: number;
    TotalSafetyFactorLoopsULS?: number;

    LoadFactorRopesALS?: number;
    LoadFactorRopesULS?: number;

    MaterialFactorRopesALS?: number;
    MaterialFactorRopesULS?: number;

    LoadFactorNettingsALS?: number;
    LoadFactorNettingsULS?: number;

    MaterialFactorNettingsALS?: number;
    MaterialFactorNettingsULS?: number;

    MaterialFactorLoopsALS?: number;
    MaterialFactorLoopsULS?: number;

    LoadFactorLoopsALS?: number;
    LoadFactorLoopsULS?: number;

    AnalysisId: number;
    Analysis: Analysis;

    @range(null, 450)
    SignedByUserId: string;

    @readOnly()
    SignedByUserName: string;
    AnalyzicCombinationCompSignByUserId: string;

    @readOnly()
    AnalyzicCombinationCompSignByUserName: string;

    AnalysisSinkerTypeId?: number;

    AnalysisDeadFishSystemId: number;
    AnalysisDeadFishSystem: AnalysisDeadFishSystem;

    AnalysisSpagetthiSinkerId: number;
    AnalysisSpagetthiSinker: AnalysisSpagetthiSinker;

    AnalysisSingleSinkerId: number;
    AnalysisSingleSinker: AnalysisSingleSinker;

    AnalysisRingSinkerId: number;
    AnalysisRingSinker: AnalysisRingSinker;
    FileAttachments: any[];

    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class AnalysisVariantLoop implements AnalysisVariantLoad {
    public static ApiUrl = 'api/analysisvariantloops';

    MinBreakingStrengthALS?: number;
    MinBreakingStrengthULS?: number;

    LoadFromAnalysisALS?: number;
    LoadFromAnalysisULS?: number;

    AnalysisId: number;
    Analysis: Analysis;

    AnalysisVariantId: number;
    AnalysisVariant: AnalysisVariant;

    AnalysisLoopId?: number;
    AnalysisLoop: AnalysisLoop;

    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class AnalysisVariantNetting implements AnalysisVariantLoad {
    public static ApiUrl = 'api/analysisvariantnettings';

    MinBreakingStrengthALS?: number;
    MinBreakingStrengthULS?: number;

    LoadFromAnalysisALS?: number;
    LoadFromAnalysisULS?: number;

    AnalysisId: number;
    Analysis: Analysis;

    AnalysisVariantId: number;
    AnalysisVariant: AnalysisVariant;

    AnalysisNettingId?: number;
    AnalysisNetting: AnalysisNetting;

    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class AnalysisVariantRope implements AnalysisVariantLoad {
    public static ApiUrl = 'api/analysisvariantropes';

    MinBreakingStrengthALS?: number;
    MinBreakingStrengthULS?: number;

    LoadFromAnalysisALS?: number;
    LoadFromAnalysisULS?: number;

    AnalysisId: number;
    Analysis: Analysis;

    AnalysisVariantId: number;
    AnalysisVariant: AnalysisVariant;

    AnalysisRopeId?: number;
    AnalysisRope: AnalysisRope;

    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class AuditLog {
    public static ApiUrl = 'api/auditlogs';

    Id: number;
    Action: number;
    @range(null, 50)
    EntityType: string;
    EntityId?: number;
    @range(null, 50)
    ParentEntityType: string;
    ParentEntityId?: number;
    @range(null, 200)
    CustomActionCode: string;
    @range(null, 500)
    CustomActionDetails: string;
    @range(null, 50)
    UserId: string;
    Created: Date;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Collector {
    public static ApiUrl = 'api/collectors';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Contact {
    public static ApiUrl = 'api/contacts';

    AquacomId?: number;
    @range(null, 50)
    Phone: string;
    @range(null, 50)
    Email: string;
    IsPrimaryContact: boolean;
    IsResponsibleForUnloading: boolean;
    IsResponsibleForOffers: boolean;
    OrderAquacomUser: boolean;
    @range(null, 1000)
    AquacomUserInfo: string;
    CustomerId: number;
    Customer: Customer;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Customer {
    public static ApiUrl = 'api/customers';

    AquacomId?: number;
    ShowAntifoulingAmountInDocuments: boolean;
    @range(null, 10)
    PreferredReportLanguage: string;
    ServiceInformationFileId?: number;
    ServiceInformationFile: StorageFile;
    NetOffers: any[];
    Nets: any[];
    Departments: any[];
    Sites: any[];
    Contacts: any[];
    @range(null, 100)
    Name: string;
    @range(null, 50)
    OrgNo: string;
    @range(null, 50)
    ExternalRef: string;
    @range(null, 100)
    Address1: string;
    @range(null, 100)
    Address2: string;
    @range(null, 50)
    Zip: string;
    @range(null, 50)
    City: string;
    @range(null, 50)
    Country: string;
    @range(null, 50)
    Phone: string;
    @range(null, 50)
    Email: string;
    @range(null, 100)
    ContactName: string;
    @range(null, 50)
    ContactPhone: string;
    @range(null, 50)
    ContactEmail: string;
    StorageFolderId?: number;
    StorageFolder: StorageFolder;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    IndustryId: number;
    Industry: Industry;

    NavisionCustomerId?: string;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class CustomerNetType {
    public static ApiUrl = 'api/customernettypes';

    IsFilterable: boolean;
    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class CuttingStrapType {
    public static ApiUrl = 'api/cuttingstraptypes';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class DeadFishSystem {
    public static ApiUrl = 'api/deadfishsystems';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class DeadFishSystemConnectionPoint {
    public static ApiUrl = 'api/deadfishsystemconnectionpoints';

    InnerBottom: boolean;
    OuterBottom: boolean;
    SpagetthiSinker: boolean;
    MinCrossRopes?: number;
    MaxCrossRopes?: number;
    LoopTypeId?: number;
    LoopType: LoopType;
    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class DeadFishSystemSetup {
    public static ApiUrl = 'api/deadfishsystemsetups';

    ForDeadFishCollector: boolean;
    ForLiftUp: boolean;
    ForConedAndSpagetthi: boolean;
    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Department {
    public static ApiUrl = 'api/departments';

    CustomerId: number;
    Customer: Customer;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class DesignType {
    public static ApiUrl = 'api/designtypes';

    @range(null, 20)
    DesignCode: string;
    DefaultNetShapeId: number;
    DefaultNetShape?: NetShape;
    SideRopeCombinations: any[];
    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;

    Drawing?: DesignTypeDrawing;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class DesignTypeSideRopeCombination {
    public static ApiUrl = 'api/designtypecombinations';

    Sides: number;
    SideRopes: number;
    DesignTypeId: number;
    DesignType: DesignType;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class DimensionClass {
    public static ApiUrl = 'api/dimensionclasses';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    ClassType: DimensionClassType;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class DisinfectionType {
    public static ApiUrl = 'api/disinfectiontypes';

    ProductId?: number;
    Product: Product;

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class DetergentType {
    public static ApiUrl = 'api/detergenttypes';

    ProductId?: number;
    Product: Product;

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class DryingMethod {
    public static ApiUrl = 'api/dryingmethods';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Equipment {
    public static ApiUrl = 'api/equipments';

    Amount: number;
    @range(null, 255)
    Comment: string;
    NetId: number;
    Net: Net;
    IsCenterBottomEquipment: boolean;
    EquipmentTypeId: number;
    EquipmentType: EquipmentType;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class EquipmentType {
    public static ApiUrl = 'api/equipmenttypes';

    IsCenterBottomEquipment: boolean;
    IsOtherEquipment: boolean;
    Components: any[];
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    ApprovedForNs9415: boolean;
    NavisionProductId?: number;
    NavisionProduct: Product;
    DeltaProductId?: number;
    DeltaProduct: Product;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class EquipmentTypeComponent {
    public static ApiUrl = 'api/equipmenttypecomponents';

    Amount: number;
    PriceCalculationTypeId: number;
    PriceCalculationType: PriceCalculationType;
    EquipmentTypeId: number;
    EquipmentType: EquipmentType;
    NettingTypeId?: number;
    NettingType: NettingType;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    ApprovedForNs9415: boolean;
    NavisionProductId?: number;
    NavisionProduct: Product;
    DeltaProductId?: number;
    DeltaProduct: Product;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class FasteningPoint {
    public static ApiUrl = 'api/fasteningpoints';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class FloaterType {
    public static ApiUrl = 'api/floatertypes';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class GeneralOffer {
    public static ApiUrl = 'api/generaloffers';

    SegmentId: number;
    Segment: Segment;
    InternalDepartmentId: number;
    InternalDepartment: InternalDepartment;
    QuoteNumber: string;
    CustomerId: number;
    Customer: Customer;
    DepartmentId?: number;
    Department: Department;
    ProducerId?: number;
    Producer: Producer;
    Quantity?: number;
    NextFollowUp?: Date;
    ValidTo?: Date;
    PlannedDeliveryDate?: Date;
    Probability: number;
    Value?: number;
    Margin?: number;
    Comment: string;
    InternalComment: string;
    OfferStatusId: number;
    OfferStatus: OfferStatus;
    GeneralOfferTypeId: number;
    GeneralOfferType: GeneralOfferType;
    ExternalRef: string;
    CreatedFromSourceId?: number;
    SourceId?: number;
    InvoicingYear?: number;
    DateTransferredToOrder?: Date;
    @range(null, 450)
    ResponsibleUserId: string;
    @readOnly()
    ResponsibleUserName: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class GeneralOfferType {
    public static ApiUrl = 'api/generaloffertypes';

    SegmentId?: number;
    Segment: Segment;
    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class HistoryItem {
    public static ApiUrl = 'api/history';

    Date: Date;
    Event: string;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Impregnation {
    public static ApiUrl = 'api/impregnations';

    ImpregnationAmount?: number;
    ImpregnationAmountToUseInOffer?: number;
    @range(null, 500)
    ImpregnationComment: string;
    ImpregnationCommentTest: string;
    @range(null, 500)
    ImpregnationTestedProdNo: string;
    TemperatureImpregnationDuringTest?: number;
    TemperatureNetBeforeImpregnation?: number;
    TemperatureImpregnationBeforeImpregnation?: number;
    MoistureNetBeforeImpregnation?: number;
    MoistureNetAfterImpregnationAndDrying?: number;
    ImpregnationMethodId?: number;
    ImpregnationMethod: ImpregnationMethod;
    DryingMethodId?: number;
    DryingMethod: DryingMethod;
    ImpregnationTypeId?: number;
    ImpregnationType: ImpregnationType;
    ImpregnationAtServiceStationId?: number;
    ImpregnationAtServiceStation: ServiceStation;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ImpregnationMethod {
    public static ApiUrl = 'api/impregnationmethods';

    ImpregnationAmountFactor?: number;
    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ImpregnationTest {
    public static ApiUrl = 'api/impregnationtests';

    Viscosity?: number;
    Density?: number;
    ImpregnationTestTypeId: number;
    ImpregnationTestType: ImpregnationTestType;
    NetId: number;
    Net: Net;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ImpregnationTestType {
    public static ApiUrl = 'api/impregnationtesttypes';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ImpregnationType {
    public static ApiUrl = 'api/impregnationtypes';

    ImpregnationAmountFactor?: number;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    ApprovedForNs9415: boolean;
    NavisionProductId?: number;
    NavisionProduct: Product;
    DeltaProductId?: number;
    DeltaProduct: Product;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    Inactive?: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class InternalDepartment {
    public static ApiUrl = 'api/internaldepartments';

    @range(null, 450)
    LeaderId: string;
    SegmentId: number;
    Segment: Segment;
    @readOnly()
    LeaderName: string;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class LaceThread {
    public static ApiUrl = 'api/lacethreads';

    BreakingStrength?: number;
    NetId: number;
    Net: Net;
    LaceThreadCategoryId?: number;
    LaceThreadCategory: LaceThreadCategory;
    LaceThreadDimensionId?: number;
    LaceThreadDimension: LaceThreadDimension;
    LaceThreadTypeId?: number;
    LaceThreadType: LaceThreadType;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class LaceThreadCategory {
    public static ApiUrl = 'api/lacethreadcategories';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class LaceThreadDimension {
    public static ApiUrl = 'api/lacethreaddimensions';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class LaceThreadType {
    public static ApiUrl = 'api/lacethreadtypes';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Loop {
    public static ApiUrl = 'api/loops';

    Amount: number;
    Comment: string;
    IsAttachmentPointCage: boolean;
    IsAttachmentPointPlumb: boolean;

    IsAttachmentPointTopLoop: boolean;
    IsAttachmentPointMageband: boolean;

    Connections?: Connection[];

    MinBreakingStrength?: number;
    NetId: number;
    Net: Net;
    LoopTypeId?: number;
    LoopType: LoopType;
    LoopPositionVerticalId?: number;
    LoopPositionVertical: LoopPositionVertical;
    LoopPositionHorizontalId?: number;
    LoopPositionHorizontal: LoopPositionHorizontal;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class LoopPositionHorizontal {
    public static ApiUrl = 'api/looppositionshorizontal';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class LoopPositionVertical {
    public static ApiUrl = 'api/looppositionsvertical';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class LoopType {
    public static ApiUrl = 'api/looptypes';

    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    ApprovedForNs9415: boolean;
    NavisionProductId?: number;
    NavisionProduct: Product;
    DeltaProductId?: number;
    DeltaProduct: Product;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Marking {
    public static ApiUrl = 'api/markings';

    Amount: number;
    Comment: string;
    NetId: number;
    Net: Net;
    MarkingTypeId: number;
    MarkingType: MarkingType;
    MarkingColorId?: number;
    MarkingColor: MarkingColor;
    MarkingPlacementId?: number;
    MarkingPlacement: MarkingPlacement;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class MarkingColor {
    public static ApiUrl = 'api/markingcolors';

    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    ApprovedForNs9415: boolean;
    NavisionProductId?: number;
    NavisionProduct: Product;
    DeltaProductId?: number;
    DeltaProduct: Product;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class MarkingPlacement {
    public static ApiUrl = 'api/markingplacements';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class MarkingType {
    public static ApiUrl = 'api/markingtypes';

    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    ApprovedForNs9415: boolean;
    NavisionProductId?: number;
    NavisionProduct: Product;
    DeltaProductId?: number;
    DeltaProduct: Product;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Material {
    public static ApiUrl = 'api/materials';

    ImpregnationAmountFactor?: number;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;

    MaterialConstituents?: MaterialConstituent[];
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class MeshSize {
    public static ApiUrl = 'api/meshsizes';

    MeshSizeMm?: number;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class MooringCalculationReport {
    public static ApiUrl = 'api/mooringcalculationreports';

    CustomerName: string;
    Address: string;
    ContactName: string;
    EmailAddress: string;
    PhoneNumber: string;
    SiteName: string;
    TechnicalStandardId: number;
    CageCountLenghtDirection: number;
    CageCountTransDirection: number;
    Depth: number;
    DepthBottomRope: number;
    Solidity: number;
    PlumbWeight: number;
    CircumferenceCage: number;
    SideCount: number;
    VcSide1: number;
    VcSide2: number;
    VcEnd1: number;
    VcEnd2: number;
    HsSide1: number;
    HsSide2: number;
    HsEnd1: number;
    HsEnd2: number;
    LineLengthSide1: number;
    LineLengthSide2: number;
    LineLengthEnd1: number;
    LineLengthEnd2: number;
    WaterDepthSide1: number;
    WaterDepthSide2: number;
    WaterDepthEnd1: number;
    WaterDepthEnd2: number;
    @range(null, 50)
    LatitudeCorner1: string;
    @range(null, 50)
    LatitudeCorner2: string;
    @range(null, 50)
    LatitudeCorner3: string;
    @range(null, 50)
    LatitudeCorner4: string;
    @range(null, 50)
    LongitudeCorner1: string;
    @range(null, 50)
    LongitudeCorner2: string;
    @range(null, 50)
    LongitudeCorner3: string;
    @range(null, 50)
    LongitudeCorner4: string;
    CustomerId: number;
    Customer: Customer;
    ReportFileId?: number;
    ReportFile: StorageFile;
    @range(null, 450)
    GeneratedByUserId: string;
    @readOnly()
    GeneratedByUserName: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  @Expose()
  export class Net {
    public static ApiUrl = 'api/nets';

    @range(null, 100)
    NetIdentifier: string;
    @range(null, 120)
    CustomerNetId: string;
    Comment: string;
    ImportantComment: string;
    DeliveryDate?: Date;
    ShipmentDate?: Date;
    ApprovedDate?: Date;
    ValidTo?: Date;
    IsSpecialProduct: boolean;
    IsNetDesign: boolean;
    IsMorenotNet: boolean;
    Impregnated: Impregnated;
    AquacomId?: number;
    @range(null, 1000)
    DeadFishOther: string;
    @range(null, 1000)
    Signature: string;
    CommentsOldNetlog: string;
    PlannedImpregnation?: number;
    PlannedHours?: number;
    CustomerId: number;
    Customer: Customer;
    SpecialProductTypeId?: number;
    SpecialProductType: SpecialProductType;
    CustomerNetTypeId?: number;
    CustomerNetType: CustomerNetType;
    SiteId?: number;
    Site: Site;
    DepartmentId?: number;
    Department: Department;
    ImpregnationId?: number;
    Impregnation: Impregnation;
    NetDimensionId?: number;
    NetDimension: NetDimension;
    SpecialProductDimensionId?: number;
    SpecialProductDimension: SpecialProductDimension;
    NetOffer: NetOffer;
    NetOrder: NetOrder;
    NetProductionDataId?: number;
    NetProductionData: NetProductionData;
    NetPreparationId?: number;
    NetPreparation: NetPreparation;
    NetStatusId: number;
    NetStatus: NetStatus;
    ProducerId?: number;
    Producer: Producer;
    DrawingFileId?: number;
    ProductionDrawingFileId?: number;
    CustomerDrawingFileId?: number;
    CertificateFileId?: number;
    DrawingFile: StorageFile;
    ProductionDrawingFile: StorageFile;
    CustomerDrawingFile: StorageFile;
    CertificateFile: StorageFile;
    ImpregnationTests: any[];
    Nettings: any[];
    Markings: any[];
    Reinforcements: any[];
    Equipment: any[];
    Ropes: any[];
    LaceThreads: any[];
    Loops: any[];
    NetOrderItem: NetOrderItem;
    FileAttachments: any[];
    IsCollect: boolean;
    @readOnly()
    Locked: boolean;
    MinBreakingStrengthNetting?: number;
    @readOnly()
    CanGenerateCertificate: boolean;
    Id: number;
    ServiceStationStorageId?: number;
    SortIndex?: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    CustomerIndustryId?: number;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NetDimension {
    public static ApiUrl = 'api/netdimensions';

    Ns9415Compliant?: boolean;
    Circumference?: number;
    BottomRopeCircumference?: number;
    ExcessMainRopePercentage?: number;
    ExcessNettingPercentage?: number;
    CircumferenceProduction?: number;
    Length?: number;
    Width?: number;
    SlopeCornerLength?: number;
    BottomLength?: number;
    BottomWidth?: number;
    HeightJumpNet?: number;
    HeightJumpNetSmallFish?: number;
    DepthBottom?: number;
    DepthBottomCenter?: number;
    DepthBottomCenterExtraBottom?: number;
    DepthSecondMainRope?: number;
    TotalDepthNet?: number;
    TotalDepth?: number;
    SlopeAngle?: number;
    SlopeAngleSide?: number;
    RemainderVolumePercentage?: number;
    Volume?: number;
    MinBreakingStrength?: number;
    OverrideMinBreakingStrengthNettings?: number;
    ActualBreakingStrength?: number;
    StretchingForce?: number;
    PlacementMageband?: number;
    PlacementExtraRope?: number;
    Area?: number;
    AreaNetting?: number;
    AreaNettingInSea?: number;
    Weight?: number;
    WeightNetting?: number;
    SideCount?: number;
    SideRopes?: number;
    LiftingRopes?: number;
    SideLength?: number;
    @range(null, 50)
    ClassZeroRef: string;
    @range(null, 50)
    ClassZeroRef_2: string;
    @range(null, 50)
    ClassZeroRef2: string;
    @range(null, 50)
    ClassZeroRef2_2: string;
    @range(null, 50)
    ClassZeroRef3: string;
    @range(null, 50)
    ClassZeroRef3_2: string;
    @range(null, 50)
    ClassZeroRef4: string;
    @range(null, 50)
    ClassZeroRef4_2: string;
    FloaterNetConnections?: number;
    MaximumTotalWeightCenterNetBottom?: number;
    MaximumLoadDescendingSystem?: number;
    MaximumAllowedDownwardPlumbing?: number;
    MaximumLoadFasteningPointCage?: number;
    MaximumAllowedWeightDescendingSystem?: number;
    MaximumLoadFastningPointDescendingSystem?: number;
    TotalLoadFromWeightsAttachedToBottomRope?: number;
    TotalLoadFromWeightAttachedToCenterOfBottom?: number;
    HasSameNettingOnAllNet: boolean;
    HasNettingOnOuterBottom: boolean;
    Hs?: number;
    Vs?: number;
    Hs2?: number;
    Vs2?: number;
    Hs3?: number;
    Vs3?: number;
    Hs4?: number;
    Vs4?: number;
    DeadFishFunnel?: boolean;
    DeadFishSystemId?: number;
    DeadFishSystem: DeadFishSystem;
    DeadFishSystemSetupId?: number;
    DeadFishSystemSetup: DeadFishSystemSetup;
    DeadFishSystemSetupId2?: number;
    DeadFishSystemSetup2: DeadFishSystemSetup;
    InnerBottomConnectionPointId?: number;
    InnerBottomConnectionPoint: DeadFishSystemConnectionPoint;
    OuterBottomConnectionPointId?: number;
    OuterBottomConnectionPoint: DeadFishSystemConnectionPoint;
    SpagetthiSinkerConnectionPointId?: number;
    SpagetthiSinkerConnectionPoint: DeadFishSystemConnectionPoint;
    IsCombiNet: boolean;
    Net: Net;
    NetStandardId?: number;
    NetStandard: NetStandard;
    NetTypeId?: number;
    NetType: NetType;
    NetIntentId?: number;
    NetIntent: NetIntent;
    NetShapeId?: number;
    NetShape: NetShape;
    FloaterTypeId?: number;
    FloaterType: FloaterType;
    DesignTypeId?: number;
    DesignType: DesignType;

    ClassZeroAnalysisId?: number;
    ClassZeroAnalysis: Analysis;

    ClassZeroAnalysis2Id?: number;
    ClassZeroAnalysis2: Analysis;

    ClassZeroAnalysis3Id?: number;
    ClassZeroAnalysis3: Analysis;

    ClassZeroAnalysis4Id?: number;
    ClassZeroAnalysis4: Analysis;

    AnalysisVariantId?: number;
    AnalysisVariant: AnalysisVariant;
    AnalysisVariant_2Id?: number;
    AnalysisVariant_2: AnalysisVariant;

    AnalysisVariant2Id?: number;
    AnalysisVariant2: AnalysisVariant;
    AnalysisVariant2_2Id?: number;
    AnalysisVariant2_2: AnalysisVariant;

    AnalysisVariant3Id?: number;
    AnalysisVariant3: AnalysisVariant;
    AnalysisVariant3_2Id?: number;
    AnalysisVariant3_2: AnalysisVariant;

    AnalysisVariant4Id?: number;
    AnalysisVariant4: AnalysisVariant;
    AnalysisVariant4_2Id?: number;
    AnalysisVariant4_2: AnalysisVariant;

    CalculatedDimensionClassId?: number;
    DimensionClassId?: number;
    DimensionClass: DimensionClass;
    OverrideDimensionClass: boolean;
    ReliabilityClass?: number;
    SquareLength?: number;
    SquareWidth?: number;
    SquareSlopeCornerLength?: number;
    BottomSquareLength?: number;
    BottomSquareWidth?: number;
    AreaData: NetDimensionAreaData;
    VolumeData: NetDimensionVolumeData;
    SectorAngel?: number;
    LengthSide?: number;
    LengthSideBottom?: number;
    RadiusCornerToCenter?: number;
    RadiusCornerToCenterBottom?: number;
    RadiusCornerToCenterBottomCone?: number;
    RadiusMiddleSideToCenter?: number;
    RadiusMiddleSideToCenterBottom?: number;
    RadiusMiddleSideToCenterBottomCone?: number;
    AreaHorizontalWaterLine?: number;
    AreaHorizontalBottomRope?: number;
    LengthSideWaterLineBottomRope?: number;
    LengthNettingOuterBottom?: number;
    LengthNettingCrossRopeOuterBottomMiddleSideToCenterBottom?: number;
    LengthNettingCrossRopeBottom?: number;
    LengthNettingCrossRopeBottom2?: number;
    LengthNettingCrossRopeInnerBottomCornerToCenter?: number;
    LengthNettingCrossRopeInnerBottomCornerToMidSide?: number;
    LengthNettingCrossRopeOuterBottom?: number;
    TotalLengthSideToBottom?: number;
    NettingBottomLength?: number;
    NettingBottomWidth?: number;
    LengthBetweenCrossRopes?: number;
    CircumferenceCage?: number;
    MaxDistanceBetweenLiftingRopes?: number;
    MaxDistanceBetweenVerticalRopes?: number;
    DistanceBetweenSideRopes?: number;
    DistanceLiftingRopes?: number;
    ExcessCircumferencePercentage?: number;
    @readOnly()
    DesignTypeShort: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NetIntent {
    public static ApiUrl = 'api/netintents';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NetOffer {
    public static ApiUrl = 'api/netoffers';

    @range(null, 50)
    QuoteNumber: string;
    QuoteIdCRM: number;
    IsSimpleOffer: boolean;
    CustomerId: number;
    Customer: Customer;
    DepartmentId?: number;
    Department: Department;
    SiteId?: number;
    Site: Site;
    ContactId?: number;
    Contact: Contact;
    @range(null, 450)
    ResponsibleUserId: string;
    NumberOfNets?: number;
    NextFollowUp: Date;
    ValidTo: Date;
    RequestedDeliveryDate?: Date;
    PlannedDeliveryDate?: Date;
    NetId: number;
    Net: Net;
    OfferStatusId: number;
    OfferStatus: OfferStatus;
    @range(null, 1000)
    CommentForCustomer: string;
    @range(null, 100)
    CustomerOrderNumber: string;
    NeedsNewDrawing: boolean;
    NeedsClassZeroCalculations: boolean;
    DeliveryAddressId?: number;
    DeliveryAddress: Address;
    UnloadingContactId?: number;
    UnloadingContact: Contact;
    AdditionalCosts: any[];
    PriceCalculations: any[];
    CurrencyId?: number;
    CurrencyRate?: number;
    PricePlace: number;
    MarginNettingPercentage: number;
    MarginRopePercentage: number;
    MarginMountingPercentage: number;
    MarginOtherPercentage: number;
    MarginAntifoulingPercentage: number;
    MarginFreightAndAnalysisPercentage: number;
    DiscountNet: number;
    DiscountAntifouling: number;
    AntifoulingApplicationCost: number;
    AntifoulingAddMarginPerLiter: number;
    RopeCutPercentage: number;
    ExtraDrawingPrice: number;
    ClassZeroCalculationPrice: number;
    MountingHourPriceDelta: number;
    MountingHourPriceOther: number;
    ExtraProductsPrice: number;
    ApprovedQualityCheck?: Date;
    NetOfferLostInfoId?: number;
    NetOfferLostInfo?: NetOfferLostInfo;
    IsCollect: boolean;
    @range(null, 450)
    ApprovedQualityCheckByUserId: string;
    @readOnly()
    ResponsibleUserName: string;
    @readOnly()
    ApprovedQualityCheckByUserName: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options()
  export class NewNetReport {
    public static ApiUrl = 'api/newnet-reports';
    ProductionPlace: string;
    QuoteNumber: number;
    IsSimpleOffer: boolean;
    CustomerName: string;
    OrderNumber: string;
    OrderId: number;
    NetOfferId: number;
    NetIdentifier: string;
    NetId: number;
    DesignType: string;
    Material: string;
    ProductionDate: Date;
    ActualHours: number;
    TypeImpregnation: string;
    EstimatedImpregnation: number;
    ActualImpregnation: number;
    ImpregnationAmountFactor: number;
    ProductionDateFrontEnd: string;
    TotalLength: number;
    Nettype: number;
    TotalDepth: number;
    Netting: number;
    PlannedHours: number;
    HoursDifference: number;
    ImpregnationDifference: number;
  }

  @options()
  export class NewNetAntifoulingReport {
    public static ApiUrl = 'api/newnet-antifouling-reports';
    ImpregnationTypeId: number;
    Name: string;
    NameEn: string;
    NameEs: string;
    SumActualIpregnation: number;
    SumWeightNetting: number;
    ImpregnationAmountFactor: number;
    CalcFactor: number;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NetOrder {
    public static ApiUrl = 'api/netorders';

    @range(null, 50)
    OrderNumber: string;
    CustomerId: number;
    Customer: Customer;
    DepartmentId?: number;
    Department: Department;
    SiteId?: number;
    Site: Site;
    ContactId?: number;
    Contact: Contact;
    @range(null, 450)
    ResponsibleUserId: string;
    NumberOfNets?: number;
    RequestedDeliveryDate?: Date;
    PlannedDeliveryDate?: Date;
    @range(null, 50)
    ProjectNumber: string;
    @range(null, 50)
    PurchaseOrderNumber: string;
    DesignDeviationDescription: string;
    NetId: number;
    Net: Net;
    NetOfferId?: number;
    NetOffer: NetOffer;
    OrderStatusId: number;
    OrderStatus: OrderStatus;
    ProducerId?: number;
    Producer: Producer;
    DeliveryAddressId?: number;
    DeliveryAddress: Address;
    UnloadingContactId?: number;
    UnloadingContact: Contact;
    Items: any[];
    @readOnly()
    ResponsibleUserName: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NetOrderItem {
    public static ApiUrl = 'api/netorderitems';

    @range(null, 20)
    NetIdentifier: string;
    NetOrderId: number;
    NetOrder: NetOrder;
    NetId?: number;
    Net: Net;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NetPreparation {
    public static ApiUrl = 'api/netpreparations';

    Comment: string;
    StrapTypeId?: number;
    StrapType: StrapType;
    CuttingStrapTypeId?: number;
    CuttingStrapType: CuttingStrapType;
    PackagingTypeId?: number;
    PackagingType: PackagingType;
    PackagingMethodId?: number;
    PackagingMethod: PackagingMethod;
    PreparationRopes: any[];
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NetProductionData {
    public static ApiUrl = 'api/netproductiondata';

    ActualHours?: number;
    ActualImpregnation?: number;
    @range(null, 100)
    TopRopeProductionNr: string;
    @range(null, 100)
    MainRopeProductionNr: string;
    @range(null, 100)
    SideRopeProductionNr: string;
    @range(null, 100)
    BottomRopeProductionNr: string;
    @range(null, 100)
    CrossRopeProductionNr: string;
    @range(null, 100)
    MagebandProductionNr: string;
    @range(null, 100)
    ExtraRopeProductionNr: string;
    @range(null, 100)
    ImpregnationProductionNr: string;
    @range(null, 500)
    NettingProductionNr: string;
    @range(null, 100)
    CenterRingProductionNr: string;
    @range(null, 100)
    LaceThreadSewingProductionNr: string;
    @range(null, 100)
    LaceThreadNettingProductionNr: string;
    @range(null, 100)
    LaceThreadReinforcementProductionNr: string;
    @range(null, 100)
    LaceThreadBendslingProductionNr: string;
    @range(null, 100)
    LaceThreadOtherProductionNr: string;
    @range(null, 100)
    PackagedNetSize: string;
    PackagedNetWeight?: number;
    ImpregnationDate?: Date;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NetShape {
    public static ApiUrl = 'api/netshapes';

    AquacomId?: number;
    ImpregnationAmountFactor?: number;
    CanUseCombiNet: boolean;
    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  export enum NetStandardVersion {
    NS9415_2009 = 2009,
    NS9415_2021 = 2021,
  }

  export enum NetStandardId {
    NS9415_2009 = 1,
    NS9415_2021 = 2,
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NetStandard {
    public static ApiUrl = 'api/netstandards';

    @range(null, 100)
    Name: string;

    @range(null, 100)
    NameEn: string;

    @range(null, 100)
    NameEs: string;

    Id: number;
    Created?: Date;
    Updated?: Date;

    @readOnly()
    IsDeleted: boolean;

    @readOnly()
    Version: NetStandardVersion;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NetStatus {
    public static ApiUrl = 'api/netstatuses';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Netting {
    public static ApiUrl = 'api/nettings';

    Sort: number;
    MinBreakingStrength?: number;
    ActualBreakingStrength?: number;
    Solidity?: number;
    Omfar?: number;
    MeshOpening?: number;
    NetId: number;
    Net: Net;
    NettingTypeId?: number;
    NettingType: NettingType;
    MeshSizeId?: number;
    MeshSize: MeshSize;
    PlacementId?: number;
    Placement: Placement;
    NylonTypeId?: number;
    NylonType: NylonType;
    MaterialId?: number;
    Material: Material;
    ThreadTypeId?: number;
    ThreadType: ThreadType;
    NettingColorId?: number;
    NettingColor: NettingColor;
    CalculatedArea?: number;
    CalculatedLength?: number;
    CalculatedWeight?: number;
    CalculatedPrice?: number;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NettingColor {
    public static ApiUrl = 'api/nettingcolors';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NettingType {
    public static ApiUrl = 'api/nettingtypes';

    Solidity?: number;
    Omfar?: number;
    Msk?: number;
    MeshOpening?: number;
    MeshSizeId?: number;
    MeshSize: MeshSize;
    NylonTypeId?: number;
    NylonType: NylonType;
    MaterialId?: number;
    Material: Material;
    ThreadTypeId?: number;
    ThreadType: ThreadType;
    ApprovedForNs9415: boolean;
    NavisionProductId?: number;
    NavisionProduct: Product;
    DeltaProductId?: number;
    DeltaProduct: Product;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    Inactive?: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NetType {
    public static ApiUrl = 'api/nettypes';

    IsFilterable: boolean;
    Circumference?: number;
    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NylonType {
    public static ApiUrl = 'api/nylontypes';

    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class OfferAdditionalCost {
    public static ApiUrl = 'api/offeradditionalcosts';

    @range(null, 500)
    Specification: string;
    Total: number;
    TotalDelta: number;
    NetOfferId: number;
    NetOffer: NetOffer;
    GeneratedCostElementType?: number;
    CurrencyId?: number;
    CostGroup: number;
    SortIndex?: number;
    SuperDetailedData: PriceCalculationDataDetails;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class OfferStatus {
    public static ApiUrl = 'api/offerstatuses';

    SortIndex: number;
    Visible?: boolean;
    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class OrderStatus {
    public static ApiUrl = 'api/orderstatuses';

    Visible?: boolean;
    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NetOfferLostInfo {
    public static ApiUrl = 'api/netofferlostinfo';

    @range(null, 32)
    ReasonCode: string;
    Comments: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class PackagingMethod {
    public static ApiUrl = 'api/packagingmethods';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class PackagingType {
    public static ApiUrl = 'api/packagingtypes';

    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    ApprovedForNs9415: boolean;
    NavisionProductId?: number;
    NavisionProduct: Product;
    DeltaProductId?: number;
    DeltaProduct: Product;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class PackagingTypeService {
    public static ApiUrl = '/api/packagingtypesservice';

    ApprovedForNs9415: boolean;
    ProductId?: number;
    Product: Product;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Placement {
    public static ApiUrl = 'api/placements';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class PreparationRope {
    public static ApiUrl = 'api/preparationropes';

    Amount?: number;
    Length?: number;
    @range(null, 500)
    Comment: string;
    CalculatedLength?: number;
    CalculatedWeight?: number;
    CalculatedPrice?: number;
    NetPreparationId: number;
    NetPreparation: NetPreparation;
    PreparationRopeTypeId: number;
    PreparationRopeType: PreparationRopeType;
    RopeDimensionId?: number;
    RopeDimension: RopeDimension;
    RopeTypeId?: number;
    RopeType: RopeType;
    PreparationRopePlacementId?: number;
    PreparationRopePlacement: PreparationRopePlacement;
    PreparationRopeKnot1Id?: number;
    PreparationRopeKnot1: PreparationRopeKnot;
    PreparationRopeKnotPlacement1Id?: number;
    PreparationRopeKnotPlacement1: PreparationRopeKnotPlacement;
    PreparationRopeKnot2Id?: number;
    PreparationRopeKnot2: PreparationRopeKnot;
    PreparationRopeKnotPlacement2Id?: number;
    PreparationRopeKnotPlacement2: PreparationRopeKnotPlacement;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class PreparationRopeKnot {
    public static ApiUrl = 'api/preparationropeknots';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class PreparationRopeKnotPlacement {
    public static ApiUrl = 'api/preparationropeknotplacements';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class PreparationRopePlacement {
    public static ApiUrl = 'api/preparationropeplacements';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class PreparationRopeType {
    public static ApiUrl = 'api/preparationropetypes';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class PriceCalculationData {
    public static ApiUrl = 'api/pricecalculationdata';

    MarginNettingPercentage: number;
    MarginRopePercentage: number;
    MarginMountingPercentage: number;
    MarginOtherPercentage: number;
    MarginAntifoulingPercentage: number;
    MarginFreightAndAnalysisPercentage: number;
    AntifoulingApplicationCost: number;
    AntifoulingAddMarginPerLiter: number;
    RopeCutPercentage: number;
    ExtraDrawingPrice: number;
    ClassZeroCalculationPrice: number;
    MountingHourPriceDelta: number;
    MountingHourPriceOther: number;
    ExtraProductsPrice: number;
    DefaultCurrencyRateEUR: number;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class PriceCalculationType {
    public static ApiUrl = 'api/pricecalculationtypes';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Producer {
    public static ApiUrl = 'api/producers';

    @range(null, 10)
    Prefix: string;
    IsMorenotCompany: boolean;
    @range(null, 100)
    CertificationIdentifier: string;
    CompanyLogoImageId?: string;
    ResponsibleQualitySignatureImageId?: string;
    ResponsibleFishFarmingSignatureImageId?: string;
    CertificationLogoImageId?: string;
    AdditionalCertificationLogoImageId?: string;
    @range(null, 100)
    ResponsibleQuality: string;
    @range(null, 100)
    ResponsibleFishFarming: string;
    CertifiedProducer: boolean;
    CompanyLogoImage: Image;
    ResponsibleQualitySignatureImage: Image;
    ResponsibleFishFarmingSignatureImage: Image;
    CertificationLogoImage: Image;
    AdditionalCertificationLogoImage: Image;
    AquacomId?: number;
    @range(null, 100)
    Name: string;
    @range(null, 50)
    OrgNo: string;
    @range(null, 50)
    ExternalRef: string;
    @range(null, 100)
    Address1: string;
    @range(null, 100)
    Address2: string;
    @range(null, 50)
    Zip: string;
    @range(null, 50)
    City: string;
    @range(null, 50)
    Country: string;
    @range(null, 50)
    Phone: string;
    @range(null, 50)
    Email: string;
    @range(null, 100)
    ContactName: string;
    @range(null, 50)
    ContactPhone: string;
    @range(null, 50)
    ContactEmail: string;
    StorageFolderId?: number;
    StorageFolder: StorageFolder;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Product {
    public static ApiUrl = 'api/products';

    @range(null, 50)
    ArticleNo: string;
    @range(null, 200)
    ExternalName: string;
    @range(null, 200)
    ExternalName2: string;
    CostPrice: number;
    WeightPerUnit?: number;
    Inventory?: number;
    MinBreakingStrength?: number;
    ProductSource: number;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class RecyclingCompany {
    public static ApiUrl = 'api/recyclingcompanies';

    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Reinforcement {
    public static ApiUrl = 'api/reinforcements';

    MeterAboveRope?: number;
    MeterBelowRope?: number;
    MeterFromCenter?: number;
    @range(null, 500)
    Comment: string;
    NetId: number;
    Net: Net;
    ReinforcementTypeId: number;
    ReinforcementType: ReinforcementType;
    ReinforcementMountingId?: number;
    ReinforcementMounting: ReinforcementMounting;
    NettingTypeId?: number;
    NettingType: NettingType;
    CalculatedArea?: number;
    CalculatedLength?: number;
    CalculatedWeight?: number;
    CalculatedPrice?: number;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    MinBreakingStrength?: number;
    ActualBreakingStrength?: number;
    Solidity?: number;
    Omfar?: number;
    MeshOpening?: number;
    MeshSize?: number;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ReinforcementMounting {
    public static ApiUrl = 'api/reinforcementmountings';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ReinforcementType {
    public static ApiUrl = 'api/reinforcementtypes';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class RingSinkerFloaterConnectionType {
    public static ApiUrl = 'api/ringsinkerfloaterconnectiontypes';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class RingSinkerNetConnectionType {
    public static ApiUrl = 'api/ringsinkernetconnectiontypes';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Rope {
    public static ApiUrl = 'api/ropes';

    Amount: number;
    MinBreakingStrength?: number;
    ActualBreakingStrength?: number;
    Sort: number;
    MeterFromWaterLine?: number;
    ExtraRopeNumber?: number;
    NetId: number;
    Net: Net;
    RopePlacementId?: number;
    RopePlacement: RopePlacement;
    RopeTypeId?: number;
    RopeType: RopeType;
    RopeHardnessId?: number;
    RopeHardness: RopeHardness;
    RopeDimensionId?: number;
    RopeDimension: RopeDimension;
    RopePlacementKind?: number;
    CalculatedLength?: number;
    CalculatedWeight?: number;
    CalculatedPrice?: number;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class RopeDimension {
    public static ApiUrl = 'api/ropedimensions';

    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class RopeHardness {
    public static ApiUrl = 'api/ropehardnesses';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class RopePlacement {
    public static ApiUrl = 'api/ropeplacements';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    Direction: RopeDirection;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class RopeType {
    public static ApiUrl = 'api/ropetypes';

    @range(null, 200)
    Remark: string;
    @range(null, 200)
    RemarkEn: string;
    @range(null, 200)
    RemarkEs: string;
    NavisionPriceBasedOnWeight: boolean;
    DeltaPriceBasedOnWeight: boolean;
    WeightPerMeter?: number;
    RopeHardnessId?: number;
    RopeHardness: RopeHardness;
    RopeDimensionId?: number;
    RopeDimension: RopeDimension;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    ApprovedForNs9415: boolean;
    NavisionProductId?: number;
    NavisionProduct: Product;
    DeltaProductId?: number;
    DeltaProduct: Product;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    Inactive?: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Segment {
    public static ApiUrl = 'api/segments';

    @range(null, 450)
    LeaderId: string;
    @readOnly()
    LeaderName: string;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Service {
    public static ApiUrl = 'api/services';

    ServiceDate?: Date;
    PlannedDeliveryDate?: Date;
    DeliveredDate?: Date;
    ReceivedDate?: Date;
    WashedDate?: Date;
    AntifouledDate?: Date;
    MeasuredDate?: Date;
    RepairedDate?: Date;
    TestedDate?: Date;
    ReceivedOrderFromCustomer?: Date;
    ReceivedByCustomer?: Date;
    DeliveredRecyclingCompany?: Date;
    OrderedWashing: boolean;
    OrderedMeasuring: boolean;
    OrderedTesting: boolean;
    OrderedRepair: boolean;
    OrderedAntifouling: boolean;
    OrderedSpaghetti: boolean;
    OrderedDelivery: boolean;
    InvoiceWashing: boolean;
    InvoiceTesting: boolean;
    InvoiceRepair: boolean;
    InvoiceAntifouling: boolean;
    InvoiceDelivery: boolean;
    InvoicedWashing?: Date;
    InvoicedTesting?: Date;
    InvoicedRepair?: Date;
    InvoicedAntifouling?: Date;
    InvoicedSpaghetti?: Date;
    InvoicedDelivery?: Date;
    ApprovedService?: Date;
    DeclinedServiceApproval?: Date;
    ScrappedByCustomer?: boolean;
    SigningOptionId?: number;
    @range(null, 100)
    SignatureService: string;
    ApprovedExtendedPeriod?: Date;
    DeclinedExtendedPeriodApproval?: Date;
    @range(null, 100)
    SignatureExtendedPeriod: string;
    @range(null, 200)
    NetReceivedFrom: string;
    @range(null, 200)
    ForeignObjects: string;
    @range(null, 200)
    NetSentTo: string;
    ServiceNumber?: number;
    @range(null, 50)
    OrderNumber: string;
    CircumferenceMeasured?: number;
    CircumferenceBottomMeasured?: number;
    LengthMeasured?: number;
    LengthMeasured2?: number;
    WidthMeasured?: number;
    WidthMeasured2?: number;
    SlopeCornerLengthMeasured?: number;
    CircumferenceExpanded?: number;
    SideLengthMeasured?: number;
    SideLengthExpanded?: number;
    BottomRopeLengthMeasured?: number;
    CircumferenceTopRopeMeasured?: number;
    JumpnetHeightMeasured?: number;
    BottomRopeLengthExpanded?: number;
    CircumferenceMeasuredStretched?: boolean;
    CircumferenceMeasuredStretchedKg?: number;
    HoursReparation?: number;
    PlannedImpregnationLiters?: number;
    ImpregnationLiters?: number;
    ImpregnationLitersRemaining?: number;
    ImpregnationLitersSumAll?: number;
    @range(null, 500)
    ImpregnationProdNo: string;
    ImpregnationHoursDrying?: number;
    @range(null, 500)
    ImpregnationCommentTest: string;
    @range(null, 500)
    ImpregnationTestedProdNo: string;
    TemperatureImpregnationDuringTest?: number;
    TemperatureNetBeforeImpregnation?: number;
    TemperatureImpregnationBeforeImpregnation?: number;
    MoistureNetBeforeImpregnation?: number;
    MoistureNetAfterImpregnationAndDrying?: number;
    AmountWaste?: number;
    Inspected?: boolean;
    TestApproved?: boolean;
    ApprovedValue?: number;
    Locked?: boolean;
    HoursWashed?: number;
    Comment: string;
    CommentFromCustomer: string;
    CommentExtendedPeriod: string;
    Extended?: boolean;
    TestConditions?: number;
    ExtensionInserts?: number;
    ExtensionLength?: number;
    ExtensionHeight?: number;
    @range(null, 20)
    UnloadingContactPhone: string;
    @range(null, 20)
    UnloadingBoatContactPhone: string;
    @range(null, 500)
    ContactInformationFromAquacom: string;
    @range(null, 200)
    StoragePlacement: string;
    PreparationComment: string;
    InternalComment: string;
    ServiceOrderInfo: string;
    ServiceCardFileId?: number;
    ServiceCardFile: StorageFile;
    ServiceTypeId: number;
    ServiceType: ServiceType;
    RecyclingCompanyId?: number;
    RecyclingCompany: RecyclingCompany;
    StrapTypeId?: number;
    StrapType: StrapType;
    CuttingStrapTypeId?: number;
    CuttingStrapType: CuttingStrapType;
    PackagingTypeId?: number;
    PackagingType: PackagingType;
    PackagingMethodId?: number;
    PackagingMethod: PackagingMethod;
    PackagingFileId?: number;
    PackagingFile: StorageFile;
    WashAndMeasurementsFileId?: number;
    WashAndMeasurementsFile: StorageFile;
    NetId: number;
    Net: Net;
    NetStandardId: number;
    NetStandard?: NetStandard;
    ServiceStationId: number;
    ServiceStation: ServiceStation;
    WashingServiceStationId?: number;
    WashingServiceStation: ServiceStation;
    MeasuringServiceStationId?: number;
    MeasuringServiceStation: ServiceStation;
    TestingServiceStationId?: number;
    TestingServiceStation: ServiceStation;
    RepairServiceStationId?: number;
    RepairServiceStation: ServiceStation;
    AntifoulingServiceStationId?: number;
    AntifoulingServiceStation: ServiceStation;
    DeliveryServiceStationId?: number;
    DeliveryServiceStation: ServiceStation;
    SpaghettiServiceStationId?: number;
    SpaghettiServiceStation: ServiceStation;
    ServiceStatusId: number;
    ServiceStatus: ServiceStatus;
    ServicePriorityId?: number;
    ServicePriority: ServicePriority;
    ImpregnationTypeId?: number;
    ImpregnationType: ImpregnationType;
    ExtensionThreadTypeId?: number;
    ExtensionThreadType: ThreadType;
    ExtensionMeshSizeId?: number;
    ExtensionMeshSize: MeshSize;
    ImpregnationMethodId?: number;
    ImpregnationMethod: ImpregnationMethod;
    DisinfectionTypeId?: number;
    DisinfectionType: DisinfectionType;
    DetergentTypeId?: number;
    DetergentType: DetergentType;
    DryingMethodId?: number;
    DryingMethod: DryingMethod;
    ReceivedFromDepartmentId?: number;
    ReceivedFromDepartment: Department;
    ReceivedFromSiteId?: number;
    ReceivedFromSite: Site;
    ContactId?: number;
    Contact: Contact;
    UnloadingContactId?: number;
    UnloadingContact: Contact;
    UnloadingBoatContactId?: number;
    UnloadingBoatContact: Contact;
    DeliveryAddressId?: number;
    DeliveryAddress: Address;
    DeliveredToDepartmentId?: number;
    DeliveredToDepartment: Department;
    DeliveredToSiteId?: number;
    DeliveredToSite: Site;
    TransportTypeId?: number;
    TransportType: TransportType;
    ServiceStationStorageId?: number;
    ServiceStationStorage: ServiceStationStorage;
    ServiceStationPipelineId?: number;
    IsInPipelineQueue: boolean;
    SortIndex?: number;
    HoursTesting?: number;
    HoursAntifouling?: number;
    HoursDelivery?: number;
    WashedHours?: number;
    SpaghettiDisassembled?: Date;
    SpaghettiNumberOfRopesDisassembled?: number;
    SpaghettiNumberOfRopesAssembled?: number;
    SpaghettiDate?: Date;
    SpaghettiWashed?: Date;
    SpaghettiAssembled?: Date;
    ServiceRepairs: any[];
    ServiceImpregnationTests: any[];
    FileAttachments: any[];
    ServiceConsumptionPreparationRopes: any[];
    ServiceConsumptionNettings: any[];
    ServiceConsumptionExtraRopes: any[];
    ServiceConsumptionLoops: any[];
    ServiceConsumptionRopes: any[];
    ServiceConsumptionThreads: any[];
    ServiceMeasurements: any[];
    ServiceImpregnations: any[];
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    IsInvoicingCompatible: boolean;
    Weight?: number;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceCheckpoint {
    public static ApiUrl = 'api/servicecheckpoints';

    ServiceId: number;
    Service: Service;
    @range(null, 1000)
    Name: string;
    @range(null, 1000)
    NameEn: string;
    @range(null, 1000)
    NameEs: string;
    IsChecked: boolean;
    CheckedDate?: Date;
    CheckedByUserId: string;
    @readOnly()
    CheckedByUserName: string;
    SortIndex: number;
    ServiceCheckpointTypeId: number;
    ServiceCheckpointGroupId: number;
    ServiceCheckpointGroup: ServiceCheckpointGroup;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceCheckpointGroup {
    public static ApiUrl = 'api/servicecheckpointgroups';

    ServiceTypeId: number;
    ServiceType: ServiceType;
    @range(null, 1000)
    Name: string;
    @range(null, 1000)
    NameEn: string;
    @range(null, 1000)
    NameEs: string;
    @range(null, 1000)
    Description: string;
    @range(null, 1000)
    DescriptionEn: string;
    @range(null, 1000)
    DescriptionEs: string;
    SortIndex: number;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceCheckpointType {
    public static ApiUrl = 'api/servicecheckpointtypes';

    SortIndex: number;
    @range(null, 1000)
    Name: string;
    @range(null, 1000)
    NameEn: string;
    @range(null, 1000)
    NameEs: string;
    ServiceCheckpointGroupId: number;
    ServiceCheckpointGroup: ServiceCheckpointGroup;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceConsumptionExtraRope {
    public static ApiUrl = 'api/serviceconsumptionextraropes';

    MeasurementJumpNet?: number;
    MeasurementWaterLin?: number;
    @range(null, 200)
    Purpose: string;
    Consumption?: number;
    RopeTypeId?: number;
    RopeType: RopeType;
    RopeDimensionId?: number;
    RopeDimension: RopeDimension;
    ServiceId: number;
    Service: Service;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceConsumptionLoop {
    public static ApiUrl = 'api/serviceconsumptionloops';

    Amount: number;
    Comment: string;
    @range(null, 200)
    ProductionNumber: string;
    ServiceId: number;
    Service: Service;
    LoopTypeId?: number;
    LoopType: LoopType;
    LoopTypeServiceId?: number;
    LoopTypeService: LoopTypeService;
    LoopPositionVerticalId?: number;
    LoopPositionVertical: LoopPositionVertical;
    LoopPositionHorizontalId?: number;
    LoopPositionHorizontal: LoopPositionHorizontal;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    ServiceInvoiceId: number;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceConsumptionNetting {
    public static ApiUrl = 'api/serviceconsumptionnettings';

    Consumption?: number;
    ConsumptionArea?: number;
    @range(null, 200)
    ProductionNumber: string;
    MeshSizeId?: number;
    MeshSize: MeshSize;
    NylonTypeId?: number;
    NylonType: NylonType;
    ThreadTypeId?: number;
    ThreadType: ThreadType;
    NettingTypeId?: number;
    NettingType: NettingTypeService;
    ServiceId: number;
    Service: Service;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    ServiceInvoiceId: number;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceConsumptionPreparationRope {
    public static ApiUrl = 'api/serviceconsumptionpreparationropes';

    Amount?: number;
    Length?: number;
    @range(null, 500)
    Comment: string;
    ServiceId: number;
    Service: Service;
    PreparationRopeTypeId: number;
    PreparationRopeType: PreparationRopeType;
    RopeDimensionId?: number;
    RopeDimension: RopeDimension;
    RopeTypeId?: number;
    RopeType: RopeType;
    RopeTypeServiceId?: number;
    RopeTypeService: RopeTypeService;
    PreparationRopePlacementId?: number;
    PreparationRopePlacement: PreparationRopePlacement;
    PreparationRopeKnot1Id?: number;
    PreparationRopeKnot1: PreparationRopeKnot;
    PreparationRopeKnotPlacement1Id?: number;
    PreparationRopeKnotPlacement1: PreparationRopeKnotPlacement;
    PreparationRopeKnot2Id?: number;
    PreparationRopeKnot2: PreparationRopeKnot;
    PreparationRopeKnotPlacement2Id?: number;
    PreparationRopeKnotPlacement2: PreparationRopeKnotPlacement;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    ServiceInvoiceId: number;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceConsumptionRope {
    public static ApiUrl = 'api/serviceconsumptionropes';

    Consumption?: number;
    MinimumBreakingLoad?: number;
    ActualBreakingStrength?: number;
    @range(null, 200)
    ProductionNumber: string;
    @range(null, 500)
    Purpose: string;
    RopeTypeId?: number;
    RopeType: RopeType;
    RopeTypeServiceId?: number;
    RopeTypeService: RopeTypeService;
    RopeDimensionId?: number;
    RopeDimension: RopeDimension;
    RopePlacementId?: number;
    RopePlacement: RopePlacement;
    ServiceId: number;
    Service: Service;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    IsSpaghetti: boolean;
    ServiceInvoiceId: number;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceConsumptionThread {
    public static ApiUrl = 'api/serviceconsumptionthreads';

    Consumption?: number;
    ConsumptionArea?: number;
    @range(null, 200)
    ProductionNumber: string;

    MeshSizeId?: number;
    MeshSize: MeshSize;

    ThreadTypeId?: number;
    ThreadType: ThreadType;

    ServiceId: number;
    Service: Service;

    NylonTypeId?: number;
    NylonType: NylonType;

    NettingTypeServiceId: number;
    NettingTypeService: NettingTypeService;

    Id: number;

    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;

    ServiceInvoiceId: number;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceConsumptionWashing {
    public static ApiUrl = 'api/serviceconsumptionwashings';

    DisinfectionConsumption?: number;
    DetergentConsumption?: number;
    DisinfectionTypeId?: number;
    DisinfectionType: DisinfectionType;
    DetergentTypeId?: number;
    DetergentType: DetergentType;
    ServiceId: number;
    Service: Service;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    Id: number;
    ServiceInvoiceId: number;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class SerivceConsumptionPackaging {
    public static ApiUrl = 'api/serviceconsumptionpackaging';
    Amount: number;
    Comment: string;
    ServiceId: number;
    service: Service;
    PackagingProductId?: number;
    PackagingProduct: PackagingProduct;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    Id: number;
    ServiceInvoiceId: number;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceImpregnation {
    public static ApiUrl = 'api/serviceimpregnations';

    @range(null, 500)
    ProductionNo: string;
    ImpregnationLiters?: number;
    ServiceId: number;
    Service: Service;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    ServiceInvoiceId: number;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceImpregnationTest {
    public static ApiUrl = 'api/serviceimpregnationtests';

    Viscosity?: number;
    Density?: number;
    ImpregnationTestTypeId: number;
    ImpregnationTestType: ImpregnationTestType;
    ServiceId: number;
    Service: Service;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceMeasurement {
    public static ApiUrl = 'api/servicemeasurements';

    MeasurementJumpNet?: number;
    MeasurementWaterLine?: number;
    MeasurementMiddleSide?: number;
    MeasurementBottom?: number;
    ServiceMeasurementTypeId: number;
    ServiceMeasurementType: ServiceMeasurementType;
    ServiceId: number;
    Service: Service;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceMeasurementType {
    public static ApiUrl = 'api/servicemeasurementtypes';

    SortIndex: number;
    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServicePriority {
    public static ApiUrl = 'api/servicepriorities';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceRepair {
    public static ApiUrl = 'api/servicerepairs';

    Repairs1To3Msk?: number;
    Repairs4To10Msk?: number;
    RepairsOver10Msk?: number;
    LooseRopes?: number;
    BrokenLaces?: number;
    @range(null, 200)
    Other: string;
    ServiceRepairTypeId: number;
    ServiceRepairType: ServiceRepairType;
    ServiceId: number;
    Service: Service;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceRepairType {
    public static ApiUrl = 'api/servicerepairtypes';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceStation {
    public static ApiUrl = 'api/servicestations';

    AquacomId?: number;
    PricePerHour?: number;
    PriceAntifoulingLiter?: number;
    PriceWasteKg?: number;
    @range(null, 100)
    ResponsibleName: string;
    @range(null, 100)
    ServiceStationIdentifier: string;
    @range(null, 500)
    ExtraNotificationReceivers: string;
    CompanyLogoImageId?: string;
    CertificationLogoImageId?: string;
    ResponsibleSignatureImageId?: string;
    CertifiedNs9415: boolean;
    CompanyLogoImage: Image;
    CertificationLogoImage: Image;
    ResponsibleSignatureImage: Image;
    ServiceStationPipelines: any[];
    @range(null, 100)
    Name: string;
    @range(null, 50)
    OrgNo: string;
    @range(null, 50)
    ExternalRef: string;
    @range(null, 100)
    Address1: string;
    @range(null, 100)
    Address2: string;
    @range(null, 50)
    Zip: string;
    @range(null, 50)
    City: string;
    @range(null, 50)
    Country: string;
    @range(null, 50)
    Phone: string;
    @range(null, 50)
    Email: string;
    @range(null, 100)
    ContactName: string;
    @range(null, 50)
    ContactPhone: string;
    @range(null, 50)
    ContactEmail: string;
    StorageFolderId?: number;
    StorageFolder: StorageFolder;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    IndustryId: number;
    Industry: Industry;
    CompanyId: number;
    Company: Company;

    ErpSystem?: ErpSystem;
    ErpSystemId?: number;

    CanGenerateInvoiceData?: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceStationAntifoulingPrice {
    public static ApiUrl = 'api/servicestationantifoulingprices';

    PricePerLiter: number;
    ImpregnationTypeId: number;
    ImpregnationType: ImpregnationType;
    ServiceStationId: number;
    ServiceStation: ServiceStation;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceStationKpi {
    public static ApiUrl = 'api/servicestationkpis';

    Year: number;
    Month: number;
    Nets: number;
    ServiceHours: number;
    AntifoulingLiters: number;
    ServiceStationId: number;
    ServiceStation: ServiceStation;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceStationPipeline {
    public static ApiUrl = 'api/servicestationpipelines';

    @range(null, 100)
    Name: string;
    Description: string;
    SortIndex: number;
    ServiceStationPipelineTypeId: number;
    ServiceStationPipelineType: ServiceStationPipelineType;
    ServiceStationId: number;
    ServiceStation: ServiceStation;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceStationPipelineType {
    public static ApiUrl = 'api/servicestationpipelinetypes';

    SortIndex: number;
    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceStationPrice {
    public static ApiUrl = 'api/servicestationprices';

    IsSpecialProductPrice: boolean;
    PriceWashing: number;
    WasteAmountKg: number;
    HoursTest: number;
    HoursRepair: number;
    AntifoulingLiters: number;
    NetTypeId?: number;
    NetType: NetType;
    SpecialProductTypeId?: number;
    SpecialProductType: SpecialProductType;
    ServiceStationId: number;
    ServiceStation: ServiceStation;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceStationStorage {
    public static ApiUrl = 'api/servicestationstorages';

    @range(null, 100)
    Name: string;
    Description: string;
    ServiceStationId: number;
    ServiceStation: ServiceStation;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    ParentId?: number;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceStatus {
    public static ApiUrl = 'api/servicestatuses';

    SortIndex: number;
    Visible?: boolean;
    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceTaskType {
    public static ApiUrl = 'api/servicetasktypes';

    SortIndex: number;
    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceTimeItem {
    public static ApiUrl = 'api/servicetimeitems';

    Hours: number;
    Date: Date;
    @range(null, 250)
    Comment: string;
    @range(null, 450)
    UserId: string;
    @range(null, 450)
    RegisteredByUserId: string;
    @readOnly()
    UserName: string;
    ServiceId: number;
    Service: Service;
    ServiceTaskTypeId: number;
    ServiceTaskType: ServiceTaskType;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
    ServiceInvoiceId: number;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ServiceType {
    public static ApiUrl = 'api/servicetypes';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Site {
    public static ApiUrl = 'api/sites';

    AquacomId?: number;
    CustomerId: number;
    Customer: Customer;
    DepartmentId?: number;
    Department: Department;
    @range(null, 100)
    Name: string;
    Id: number;
    AquacomModuleId?: number;
    Hs: string;
    Vc: string;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class SpecialProductDimension {
    public static ApiUrl = 'api/specialproductdimensions';

    Length?: number;
    Width?: number;

    LengthFavn?: number;
    WidthFavn?: number;

    Circumference?: number;
    Height?: number;
    Depth?: number;
    Area?: number;
    Volume?: number;
    Weight?: number;
    Net: Net;
    Id: number;
    Omfar: string;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class SpecialProductType {
    public static ApiUrl = 'api/specialproducttypes';

    HasLength: boolean;
    HasWidth: boolean;
    HasCircumference: boolean;
    HasHeight: boolean;
    HasDepth: boolean;
    HasArea: boolean;
    HasVolume: boolean;
    HasWeight: boolean;
    HasValidTo: boolean;
    HasCertificate: boolean;
    HasOmfar: boolean;
    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class StrapType {
    public static ApiUrl = 'api/straptypes';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class ThreadType {
    public static ApiUrl = 'api/threadtypes';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class NetMaskType {
    public static ApiUrl: string = 'api/netmasktypes';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Variant: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class TransportType {
    public static ApiUrl = 'api/transporttypes';

    @range(null, 100)
    Name: string;
    @range(null, 100)
    NameEn: string;
    @range(null, 100)
    NameEs: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class WeekReport {
    public static ApiUrl = 'api/weekreports';

    ReportYear: number;
    ReportWeek: number;
    SegmentId: number;
    Segment: Segment;
    InternalDepartmentId: number;
    InternalDepartment: InternalDepartment;
    ConfirmedDate?: Date;
    RemindedDate?: Date;
    InvoicedInPeriod?: number;
    NewOrdersInPeriod?: number;
    BacklogStartOfPeriod?: number;
    BacklogAdjustments?: number;
    BacklogEndOfPeriod?: number;
    Comment: string;
    @range(null, 450)
    ConfirmedByUserId: string;
    @readOnly()
    ConfirmedByUserName: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  export class CopyNetDto {
    ExistingNetId?: number;
    NewNetIdentifier: string;
  }

  export class CreateAnalysisDto {
    Comment: string;
  }

  export class CreateNetDto {
    IsSpecialProduct: boolean;
    SpecialProductTypeId?: number;
    NetIdentifier: string;
    CustomerId: number;
    DepartmentId?: number;
    SiteId?: number;
    ContactId?: number;
    ProducerId?: number;
    Comment: string;
    DeliveryDate?: Date;
  }

  export class CreateOfferDto {
    CopyNetDesignFromExistingNetId?: number;
    QuoteNumber: string;
    IsSimpleOffer: boolean;
    DesignTypeId?: number;
    IsCombiNet: boolean;
    CustomerId: number;
    DeliveryAddressId?: number;
    DepartmentId?: number;
    SiteId?: number;
    ContactId?: number;
    UnloadingContactId?: number;
    ProducerId?: number;
    ValidTo: Date;
    RequestedDeliveryDate?: Date;
    NumberOfNets: number;
    ResponsibleUserId: string;
    CurrencyId: number;
  }

  export class CreateOrderDto {
    NetOfferId: number;
    OrderNumber: string;
    ProjectNumber: string;
    PurchaseOrderNumber: string;
    Items: any[];
    PlannedDeliveryDate?: Date;
  }

  export class CreateServiceDto {
    Id: number;
    ServiceTypeId?: number;
    NetId: number;
    ContactId?: number;
    ReceivedDate?: Date;
    StoragePlacement: string;
    ReceivedFromDepartmentId?: number;
    ReceivedFromSiteId?: number;
    CustomerOrderedRepairAndDelivery: boolean;
    PlannedDeliveryDate?: Date;
    ServicePriorityId?: number;
    ServiceStationId: number;
    OrderNumber: string;
  }

  export class ForgotPasswordDto {
    Email: string;
  }

  export class Image {
    Id: string;
    @range(null, 200)
    Name: string;
    Length: number;
    ContentType: string;
  }

  export class LoginDto {
    Email: string;
    Password: string;
  }

  export class MergeCustomerInfo {
    OldCustomerId: number;
    NewCustomerId: number;
  }

  export class NetDimensionAreaData {
    AreaWall?: number;
    AreaWallExcess?: number;
    AreaWallCombiTopLargeFish?: number;
    AreaWallCombiTopLargeFishExcess?: number;
    AreaWallCombiBottomSmallFish?: number;
    AreaWallCombiBottomSmallFishExcess?: number;
    AreaOuterBottom?: number;
    AreaOuterBottomExcess?: number;
    AreaInnerBottom?: number;
    AreaInnerBottomExcess?: number;
    AreaJumpingNet?: number;
    AreaJumpingNetExcess?: number;
    AreaReinforcementMainRope?: number;
    AreaReinforcementMainRopeExcess?: number;
    AreaReinforcementBottomRope?: number;
    AreaReinforcementBottomRopeExcess?: number;
    AreaReinforcementExtraRope?: number;
    AreaReinforcementExtraRopeExcess?: number;
    AreaReinforcementBottom?: number;
    AreaReinforcementBottomExcess?: number;
    AreaReinforcementTotal?: number;
    AreaReinforcementTotalExcess?: number;
    AreaTotal?: number;
    AreaTotalExcess?: number;
    AreaInnerNetInSea?: number;
    AreaInnerNetInSeaExcess?: number;
    AreaWashable?: number;
    AreaWashableTop?: number;
    AreaWashableBottom?: number;
  }

  export class NetDimensionVolumeData {
    VolumeToBottomRope?: number;
    VolumeInnerBottom?: number;
    VolumeOuterBottom?: number;
    VolumeTotal?: number;
    VolumeCombiLargeFish?: number;
    VolumeCombiSmallFish?: number;
    VolumeWashable?: number;
    VolumeWashableTop?: number;
    VolumeWashableBottom?: number;
  }

  export class PriceCalculationDataDetails {
    Type: string;
    Placement: string;
    Specification: string;
    CostPriceNavision?: number;
    CostPriceDelta?: number;
    Quantity?: number;
    Amount?: number;
    Weight?: number;
    Length?: number;
    Area?: number;
    ArticleNoNavision: string;
    ArticleNoDelta: string;
    PriceComment: string;
    PriceUnitNavision: string;
    PriceUnitDelta: string;
    PriceQuantityNavision?: number;
    PriceQuantityDelta?: number;
    SalesPriceNavision?: number;
    SalesPriceDelta?: number;
    MarginPercentageNavision?: number;
    MarginPercentageDelta?: number;
  }

  export class RegisterUserDto {
    Name: string;
    Email: string;
    PhoneNumber: string;
    Password: string;
  }

  export class ResetPasswordDto {
    Email: string;
    Code: string;
    Password: string;
  }

  export class RopeTypeForNetDetail {
    RopeId?: number;
    Amount: number;
    ActualBreakingStrength?: number;
    RopeTypeId?: number;
    RopeType: RopeType;
    Dimension: RopeDimension;
    Hardness: RopeHardness;
    MeterFromWaterLine?: number;
    ExtraRopeNumber?: number;
    IsForCombiNetSmallFish?: boolean;
  }

  export class RopeTypesForNet {
    TopRope: RopeTypeForNetDetail;
    TopRopeSmallFish: RopeTypeForNetDetail;
    WaterlineRope: RopeTypeForNetDetail;
    WaterlineRopeSmallFish: RopeTypeForNetDetail;
    VerticalRope: RopeTypeForNetDetail;
    BottomRope: RopeTypeForNetDetail;
    CrossRope: RopeTypeForNetDetail;
    ExtraHorizontalRopes: any[];
    MinBreakingStrength?: number;
    LoopRingSetupId?: number;
  }

  export class ServiceMeasurementManualData {
    MeasurementPlacement: number;
    Values: any[];
  }

  export class ServiceQueueData {
    ServiceId: number;
    NetId: number;
    NetIdentifier: string;
    CustomerName: string;
    AntifouledDate?: Date;
    WashedDate?: Date;
    RepairedDate?: Date;
    TestedDate?: Date;
    PlannedDeliveryDate?: Date;
    OrderedWashing: boolean;
    OrderedTesting: boolean;
    OrderedRepair: boolean;
    OrderedAntifouling: boolean;
    ServiceStationPipelineId?: number;
    IsInPipelineQueue: boolean;
    SortIndex?: number;
    Netting: string;
    NetTypeName: string;
    CircumferenceProduction?: number;
  }

  export class ServiceRepairCommentDto {
    OtherOld: string;
    OtherNew: string;
  }

  export class StorageFile {
    Length: number;
    @range(null, 1000)
    Comment: string;
    FolderId: number;
    AquacomAttachmentId?: number;
    @range(32, 32)
    Checksum: number[];
    Folder: StorageFolder;
    @readOnly()
    RemoteName: string;
    @range(null, 100)
    Name: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  export class StorageFolder {
    @readOnly()
    FolderIdentifier: string;
    Files: any[];
    @readOnly()
    RemoteName: string;
    Id: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  export class UpdateImportantCommentDto {
    ImportantComment: string;
  }

  export class UserData {
    Id: string;
    Name: string;
    Language: string;
    Email: string;
    PhoneNumber: string;
    ProducerId?: number;
    ProducerName: string;
    ServiceStationId?: number;
    ServiceStationName: string;
    MooringStationId?: number;
    MooringStationName: string;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Industry {
    public static ApiUrl = 'api/industries';

    Id: string;
    @range(null, 100)
    Name: string;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  export enum Industries {
    Aqua = 1,
    Fishery = 2,
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class Company {
    public static ApiUrl = 'api/companies';

    Id: string;
    @range(null, 100)
    Title: string;
    @range(null, 100)
    InternalName: string;
    SortIndex: number;
    Created?: Date;
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  export enum Companies {
    Morenot = 'morenot',
    VoninRefa = 'voninrefa',
    Hampidjan = 'hampidjan',
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class StorageHistory {
    public static ApiUrl = 'api/storagehistory';

    Id: number;
    NetId?: number;
    Net?: Net;
    In: Date;
    Out?: Date;
    Title: string;
    Comment: string;
    ServiceId?: number;

    ServiceStationStorageId: number;
    ServiceStationStorage: ServiceStationStorage;
    StorageInvoiceId?: number;
    StorageInvoice?: StorageInvoice;

    PreInvoiceState?: string;
    AutoCreatedByInvoiceId?: number;

    TimeStartIn: Date;
    TimeEndIn: Date;
    TimeSpentIn: number;

    TimeStartOut: Date;
    TimeEndOut: Date;
    TimeSpentOut: number;

    @readOnly()
    Created?: Date;
    @readOnly()
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }
  export class StorageHistoryItemDto {
    Id: number;
    NetId: string;
    CustomerId: number;
    CustomerName: string;
    SpecialProductTypeName: string;
    ServiceStationStorageName: string;

    In: Date;
    Out?: Date;
    Invoiced: boolean;
    DaysInStorage: number;
    StorageInvoiceId?: number;
  }

  @options(QueryOption.Filter | QueryOption.OrderBy | QueryOption.Top | QueryOption.Skip)
  export class StorageInvoice {
    public static ApiUrl = 'api/storageinvoices';

    Id: number;
    CustomerId: number;
    ServiceStationId: number;
    Invoiced?: Date;

    @readOnly()
    Created?: Date;
    @readOnly()
    Updated?: Date;
    @readOnly()
    IsDeleted: boolean;
  }

  export class StorageViewPipeline {
    ServiceStationStorageId: number;
    Name: string;
    Pipelines?: StorageViewPipeline[];
    Data?: StorageViewPipelineItem[];
  }
  export class StorageViewPipelineItem {
    Id: number;
    NetIdentifier: string;
    CustomerName: string;
    NetTypeName?: string;
    CircumferenceProduction?: number;
    Netting?: string;
    ServiceStationStorageId?: number;
    SortIndex: number;
    _isFilteredOut: boolean;
  }

  export class ServiceUser {
    UserId: string;
    UserName: string;
    StationName: string;
  }
}
